import { MinusIcon } from '@/assets/icons/minus';
import { PlusIcon } from '@/assets/icons/plus';
import { decrement, increment } from '@/core/components/counter/counter-service';
import color from '@/core/features/styles/color';
import { bemModule } from '@/core/utils/bem-classname';

import styles from './counter-with-label.module.scss';

const bem = bemModule(styles);

export type CounterWithLabelProps = {
    count: number | string;
    disableIncrease?: boolean;
    exeedableText?: string;
    iconSize?: number;
    isLabelError?: boolean;
    isValueError?: boolean;
    label: string;
    labelPosition?: 'space-between' | 'start';
    max?: number;
    min: number;
    onDecrease: (nextCount: number, event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
    onIncrease: (nextCount: number, event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
    steps?: number;
};

export default function CounterWithLabel({
    count,
    disableIncrease,
    exeedableText,
    iconSize = 30,
    isLabelError,
    isValueError,
    label,
    labelPosition = 'start',
    max,
    min,
    onDecrease,
    onIncrease,
    steps = 1,
}: CounterWithLabelProps) {
    const isMinCount = count === min;

    const handleDecrement = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        if (typeof count !== 'number') {
            return;
        }
        return decrement(event, count, steps, min, onDecrease);
    };

    const handleIncrement = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        if (typeof count !== 'number') {
            return;
        }
        return increment(event, count, steps, onIncrease, max);
    };

    return (
        <div className={bem(styles.counterWrapper, { [labelPosition]: true })}>
            <span className={bem(styles.label, { isLabelError })}>{label}</span>
            <div className={`flex-space-between-horizontal ${styles.upDownWrapper}`}>
                <button
                    className={`flex-center ${bem(styles.button, { isMinCount })}`}
                    data-qa-id={'qa-counter-with-label-minus-button'}
                    onClick={handleDecrement}
                    style={{ height: iconSize, width: iconSize }}
                >
                    <MinusIcon
                        color={isMinCount ? color('dustyGray') : color('white')}
                        height={iconSize}
                        width={iconSize}
                    />
                </button>
                <div className={bem(styles.count, { isValueError })}>
                    {exeedableText ? <span>{exeedableText}</span> : count}
                </div>
                <button
                    className={`flex-center ${bem(styles.button, { disableIncrease })}`}
                    data-qa-id={'qa-counter-with-label-plus-button'}
                    onClick={handleIncrement}
                    style={{ height: iconSize, width: iconSize }}
                >
                    <PlusIcon
                        color={disableIncrease ? color('dustyGray') : color('white')}
                        height={iconSize}
                        width={iconSize}
                    />
                </button>
            </div>
        </div>
    );
}
